import env from "./env";
const server = env.apiLink;

const api = {
  // auth
  login: `user/login`,
  token: `user/login/token`,
  signup: `user/signup`,
  verifyEmail: "user/verify-email",
  resendOtpverifyEmail: "user/resend-verify-email",

  //candidate
  getCandidates: "contact/get-candidates",
  addCandidate: "contact/add-candidate",
  updateCandidate: "contact/update-candidate",
  deleteCandidate: "contact/delete-candidate",

  //company
  getCompany: "",
  companyList: "company/lists",
  addCompany: "company/add",
  updateCompany: "company/update",
  deleteCompany: "company/delete",
  addSkill: "skill/add",
  listSkill: "skill/lists",


  //company contact
  getCompanyContact: "company/contacts",
  addCompanyContact: "company/add-contact",
  deleteCompanyContact: "company/delete-contact",
  updateCompanyContact: "company/update-contact",

  createRecruiterProfile: `recruiter-profile`,
  resetPassword: `auth/reset-password`,
  registerEmailVerify: `auth/email-verify`,

  //assessment
  listAssessment: `assessment/lists`,
  createAssessment: `assessment/create-assessment`,
  deleteAssessment: `assessment/delete-assessment`,
  assessmentDetails: `assessment/details`,
  updateAssessment: `assessment/update-assessment`,
  deleteAssessment: `assessment/delete-assessment`,
  createCompany: `assessment/create-company`,

  //assessment section
  createSection: `assessment/create-section`,
  updateSection: `assessment/update-section`,
  getSection: `assessment/section`,
  updateSection: `assessment/update-section`,
  deleteSection: `assessment/delete-section`,

  //assessment question
  updateQuestion: `assessment/update-question`,
  createQuestion: `assessment/create-question`,
  getQuestionBySectionId: `assessment/question`,
  updateQuestion: `assessment/update-question`,
  deleteQuestion: `assessment/delete-question`,
};

export default api;
