import handleError from "./handle-error";
import api from "../api";
import axios from "../utils/axios.instance";

export const addCandidate = async (payload) => {
  try {
    const { data } = await axios.post(api.addCandidate, payload);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const updateCandidate = async (payload) => {
  try {
    const { data } = await axios.patch(api.updateCandidate, payload);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const getCandidates = async (payload) => {
  try {
    const { data } = await axios.post(api.getCandidates, payload);

    return data;
  } catch (err) {
    return handleError(err);
  }
};

// Skills
export const addSkill = async (payload) => {
  try {
    const { data } = await axios.post(api.addSkill, payload);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const listSkill = async () => {
  try {
    const { data } = await axios.get(api.listSkill + "?type=1");
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const getSkills = async (candidateId) => {
  try {
    const { data } = await axios.get(
      api.listSkill + `?type=1&candidateId=${candidateId}`
    );
    return data;
  } catch (err) {
    return handleError(err);
  }
};
