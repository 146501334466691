import { useForm } from "react-hook-form";
import CustomInput from "../../../../../components/CustomInput/CustomInput";
import CustomPhoneNumberInput from "../../../../../components/CustomPhoneNumberInput/CustomPhoneNumberInput";
import { useEffect, useRef, useState } from "react";
import regexList from "../../../../../utils/regexList";
import FolderIcon from "../../../../../assets/svg/FolderIcon";
import { useSnackbar } from "notistack";
import TrashIcon from "../../../../../assets/svg/TrashIcon";
import {
  addCompany,
  getCompanyList,
  updateCompany,
} from "../../../../../endpoints/company.service";
import { useNavigate, useParams } from "react-router-dom";
import { extractDialCode } from "../../../../../utils/helperFunction";

const AboutSection = ({ companyDetails, setCompanyDetails }) => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const filePickerRef = useRef();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });
  const [isEdit, setIsEdit] = useState(!companyId);
  const [selectedLogo, setSelectedLogo] = useState(null);

  const savePersonalDetails = (data) => {
    if (!isEdit) {
      setIsEdit(true);
      return;
    }
    const payload = {
      ...data,
      countryCode: extractDialCode("+" + data?.phoneNumber)?.dialCode,
      phoneNumber: extractDialCode("+" + data?.phoneNumber)?.localNumber,
    };

    if (companyId) {
      setIsEdit(false);
      payload.companyId = companyId;
      updateCompany(payload)
        .then((response) => {
          const { success, data } = response;
          if (success) {
            setCompanyDetails(data);
            setIsEdit(false);
            enqueueSnackbar("Details updated successfully", {
              variant: "success",
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar(err, { variant: "error" });
        });
    } else {
      addCompany(payload)
        .then((response) => {
          const { success, data } = response;
          if (success) {
            navigate(`/contacts/edit-company/${data?._id}`);
            setCompanyDetails(data);
            setIsEdit(false);
            enqueueSnackbar("Details added successfully", {
              variant: "success",
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar(err, { variant: "error" });
        });
    }
  };

  useEffect(() => {
    if (companyDetails) {
      [
        "name",
        "address1",
        "address2",
        "city",
        "zipCode",
        "email",
        "websiteUrl",
        "region",
        "country",
      ]?.forEach((item) => setValue(item, companyDetails[item]));
      setValue(
        "phoneNumber",
        companyDetails?.countryCode + companyDetails?.phoneNumber
      );
    }
  }, [companyDetails]);

  return (
    <form
      onSubmit={handleSubmit(savePersonalDetails)}
      className="border rounded-lg px-4 py-4 mt-5"
    >
      <div className="flex items-center justify-between">
        <label className="font-semibold text-sm">Company Details</label>
        <button
          type="submit"
          className="group flex items-center justify-center font-semibold text-sm realtive"
        >
          <span className="z-50">{isEdit ? "Save" : "Edit"}</span>
        </button>
      </div>
      {/* Form container */}
      <div className="flex itesm-center gap-5 mt-6">
        {/* Col 1 */}
        <div className="flex flex-col flex-1 gap-5">
          <CustomInput
            label={"Company Name"}
            disabled={!isEdit}
            placeholder={"Company's Name"}
            inActiveOutlineColor="border border-px"
            bgColor={"bg-white"}
            name={"name"}
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
          />

          <CustomInput
            label={"Address Line 1"}
            disabled={!isEdit}
            placeholder={"Enter Street Name"}
            inActiveOutlineColor="border border-px"
            bgColor={"bg-white"}
            name={"address1"}
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
          />

          <CustomInput
            label={"City"}
            disabled={!isEdit}
            placeholder={"Enter City"}
            inActiveOutlineColor="border border-px"
            bgColor={"bg-white"}
            name={"city"}
            control={control}
            errors={errors}
          />

          <CustomInput
            label={"Postcode/Zipcode"}
            disabled={!isEdit}
            placeholder={"Enter postcode"}
            inActiveOutlineColor="border border-px"
            bgColor={"bg-white"}
            name={"zipCode"}
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
          />

          <CustomInput
            label={"General Email Address"}
            disabled={!isEdit}
            placeholder={"abc@gmail.com"}
            inActiveOutlineColor="border border-px"
            bgColor={"bg-white"}
            name={"email"}
            rules={{
              required: "This field is required.",
              pattern: {
                value: regexList?.email, // Regex for email validation
                message: "Please enter a valid email address.", // Custom error message for pattern validation
              },
            }}
            control={control}
            errors={errors}
          />
        </div>
        {/* Col 2 */}
        <div className="flex flex-col flex-1 gap-5">
          <CustomInput
            label={"Website URL"}
            disabled={!isEdit}
            placeholder={"Enter website URL"}
            inActiveOutlineColor="border border-px"
            bgColor={"bg-white"}
            name={"websiteUrl"}
            control={control}
            errors={errors}
          />

          <CustomInput
            label={"Address Line 2"}
            disabled={!isEdit}
            placeholder={"Enter Street Name"}
            inActiveOutlineColor="border border-px"
            bgColor={"bg-white"}
            name={"address2"}
            control={control}
            errors={errors}
          />

          <CustomInput
            label={"Region"}
            disabled={!isEdit}
            placeholder={"Enter Region"}
            inActiveOutlineColor="border border-px"
            bgColor={"bg-white"}
            name={"region"}
            control={control}
            errors={errors}
          />

          <CustomInput
            label={"Country"}
            name={"country"}
            control={control}
            inActiveOutlineColor="border border-px"
            bgColor={"bg-white"}
            errors={errors}
            type="dropdown"
            disabled={!isEdit}
            placeholder={"Select Country"}
            dropdownList={["USA", "UK", "New Zealand"]}
          />

          <CustomInput
            type="phone"
            label={"General Phone Number"}
            name={"phoneNumber"}
            disabled={!isEdit}
            inActiveOutlineColor="border border-px"
            control={control}
            errors={errors}
          />
        </div>
        {/* Col 3 */}
        <div className="flex flex-col flex-1 gap-5">
          <div className="flex flex-col">
            <span className="font-montserrat text-primary font-medium text-xs mb-2">
              Upload Company Logo
            </span>
            <button
              onClick={() => filePickerRef?.current?.click()}
              type="button"
              className="flex items-center border rounded-lg border overflow-hidden pr-2"
            >
              <span className="text-sm text-[#6f6f6f] font-medium py-2.5 px-3 bg-[#E9EAF0]">
                PNG
              </span>
              {selectedLogo ? (
                <span className="text-xs font-medium my-auto px-3 text-[#6F6F6F] flex-1 line-clamp-1">
                  {selectedLogo?.name}
                </span>
              ) : (
                <span className="text-xs font-medium my-auto px-3 text-[#6F6F6F] flex-1">
                  Drop files here
                  <span className="text-light-grey"> or</span>
                  <span className="text-secondary"> browse</span>
                </span>
              )}
              <FolderIcon className={"w-8 h-8"} />
            </button>
            <input
              ref={filePickerRef}
              onChange={(e) => setSelectedLogo(e?.target?.files[0])}
              type="file"
              accept="image/png"
              className="hidden"
            />
          </div>

          {/* Image Preview */}
          {selectedLogo ? (
            <div className="flex relative w-full">
              <button
                type="button"
                className="absolute right-6"
                onClick={() => setSelectedLogo(null)}
              >
                <TrashIcon className={"w-5 h-5"} />
              </button>
              <img
                src={URL.createObjectURL(selectedLogo)}
                className="w-48 h-48 rounded-full m-auto"
              />
            </div>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default AboutSection;
